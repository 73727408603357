import forList from "@/mixin/forList";

export default {
    name: "empSeasonBonusList",
    noFetchFirst: true,
    mixins: [forList],

    data() {
        return {
            SP_: {
                pageNum: 1,
                pageSize: 10,
                seasonId: '',
                searchName: '',
                searchRegion: [], // 搜索区域
            },
            seasonList: [],
            fromdetail: false,
            localempSeasonBonusListParams: '',
            formempSeasonBonusDetail: 0,
            enumIsReady: false, // 以下几个远程的选项数据是否已经获得
        };
    },
    created() {
        this.localempSeasonBonusListParams = JSON.parse(localStorage.getItem("empSeasonBonusListParams"))
        this.formempSeasonBonusDetail = localStorage.getItem("formempSeasonBonusDetail")

        this.auth.export = this.$hasAuthFor("api/season/downSeasonGrantEmpBonus");


        if (this.$route.query.type == 1 && this.formempSeasonBonusDetail == 1) {
            if (this.localempSeasonBonusListParams) {//来自详情页返回 
                this.fromdetail = true
            } else {
                this.fromdetail = false
                localStorage.removeItem("empSeasonBonusListParams");
            }
        }
        this.auth.orderDetail = this.$hasAuthFor("api/season/queryGrantEmpBonusDetailList");
        const { params } = this.$route;
        if (Object.keys(params).length) {
            for (let key in params) {
                if (key === "searchHrefType") this.searchHrefType = params[key];
                else if (Object.prototype.hasOwnProperty.call(this.SP_, key))
                    this.SP_[key] = params[key]
            }
            this.showAdvanceSearch = true;
            this.handleSearch();
        }
        else {
            this.queryPreviousSeasonId()
        }
    },

    methods: {
        // 点击导出员工赛季奖金
        async handleExportBtn() {
            let loadingRef,
                url = "/api/season/downSeasonGrantEmpBonus";
            try {
                await this.$confirm("确定导出吗？", "系统提示")
                loadingRef = this.$loading();
                const data = this.extractParams(true);
                const res = await this.$axios({
                    method: "post", url, data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },

        // 查询上一个赛季  默认加载
        async queryPreviousSeasonId() {
            await this.fetchEnum()
            const res = await this.$axios({
                url: "/api/season/queryPreviousSeasonId",
                method: "post"
            });

            if (res.code === 2000) {
                if (this.fromdetail) {
                    this.SP_.pageNum = this.localempSeasonBonusListParams.pageNum
                    this.SP_.pageSize = this.localempSeasonBonusListParams.pageSize
                    this.SP_.seasonId = this.localempSeasonBonusListParams.seasonId
                    this.SP_.searchName = this.localempSeasonBonusListParams.searchName
                    if (this.localempSeasonBonusListParams.searchRegion) {
                        this.SP_.searchRegion = this.localempSeasonBonusListParams.searchRegion.split(',').map(Number)
                    }
                } else {
                    this.SP_.seasonId = res.data.seasonId
                }
                this.fetchData();


            } else if (res.code !== 1003)
                throw res
        },

        async fetchEnum() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/order/queryOrderParam",
                method: "post"
            });

            if (res.code === 2000) {
                this.seasonList = res.data.seasonList
                this.regionList = res.data.regionList

                console.log(this.SP_);


            } else if (res.code !== 1003)
                throw res
        },
        // 点击高级搜索
        handleGJBtn() {
            if (this.showAdvanceSearch)
                this.closeAdvanceSearch()
            else this.handleAdvancedSearchBtn()
        },
        handleSearchReginChange(e) {

        },
        async fetchData() {
            const loading = this.$loading();
            let data = { ...this.extractParams() }
            data.pageNum = this.SP_.pageNum
            data.pageSize = this.SP_.pageSize
            data.searchName = this.SP_.searchName
            data.seasonId = this.SP_.seasonId
            if (this.SP_.searchRegion) {
                data.searchRegion = this.SP_.searchRegion.join()
            }
            try {
                const res = await this.$axios({
                    url: "/api/season/querySeasonGrantEmpBonus",
                    method: "post",
                    data: data
                });

                if (res.code === 2000) {
                    this.total = res.data.count;
                    this.tableData = res.data.seasonEmpList;

                    this.fromdetail = false
                    localStorage.removeItem("empSeasonBonusListParams");
                    localStorage.removeItem("formempSeasonBonusDetail");
                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },
        handleCommissionDetail(item) {
            let data = { ...this.extractParams() }
            data.seasonId = this.SP_.seasonId
            localStorage.setItem("empSeasonBonusListParams", JSON.stringify(data));
            this.$router.push({
                path: `/commission/empSeasonBonusList/detail/${item.id}`,
                query: {
                    empId: item.empId
                }
            });
        },
    }
};